import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import msIcon from "../assets/images/ms-icon.svg";
import defaultLogo from "../assets/images/logo.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./Login.scss";
import CustomInput from "../components/CustomInput";
import { validateEmail, validatePassword } from "../utils/validator";
import { Error } from "@progress/kendo-react-labels";
import accountService from "../services/account.service";
import { UserLoginRequest } from "../types/UserLoginRequest";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { Loader } from "@progress/kendo-react-indicators";
import { useMsal } from "@azure/msal-react";
import { AccountInfo, SilentRequest } from "@azure/msal-browser";
import LoadingOverlay from "../components/LoadingOverlay";
import { AppTokenRequest } from "../types/AppTokenRequest";
import { Dictionary } from "../types/Dictionary";
import useLocale from "../hooks/useLocale";
import Swal from "sweetalert2";
import useBranding from "../hooks/useBranding";
import { UserNameTempTokenDto } from "../types/UserNameTempTokenDto";

const Mfa: React.FC = () => {
  const localeCtx = useLocale();
  const brandingCtx = useBranding();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState<string>();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [userMfaModes, setUserMfaModes] = useState<string[]>([]);
  const [selectedMfaMode, setSelectedMfaMode] = useState<string>("");
  const [redirecting, setRedirecting] = useState<boolean>(true);
  const [disableResendEmail, setDisableResendEmail] = useState<boolean>(false)
  const [validatingCode, setValidatingCode] = useState<boolean>(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true)
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["mfa"]);

  const clientId = searchParams.get("clientId") ?? "";
  const redirectUri = searchParams.get("redirectUri") ?? "";
  const userName = searchParams.get("username") ?? "";
  const tempToken = searchParams.get("tempToken") ?? "";
  //#region useEffects
  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        setTranslationsLoading(true);
        const resp = await localeCtx?.setComponentTranslations("mfa");
        setTranslations(resp);
      } catch (err) {
        setTranslations(
          localeCtx?.selectedLocale?.previous.componentTranslations["mfa"]
        );
        localeCtx?.setPreviousAppLocale("login");
        if (localeCtx?.localeSwitchFailed) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Couldn't Switch Language",
          });
        }
      } finally {
        setTimeout(() => {
          setTranslationsLoading(false);
        }, 100);
      }
    };

    if (!localeCtx?.selectedLocale?.current.componentTranslations["login"]) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    if (!clientId && !tempToken && !userName) {
      // redirect to unauthorized
      navigate(`/unauthorized`, { replace: true });
    } else {
      validateTempToken();
      authenticateUserWithMFA();
    }
  }, []);
  //#endregion useEffects

  //#region Locale Translation Methods
  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };
  //#endregion Locale Translation Methods

  //#region Handlers
  let additionalSearchParam = "";
      searchParams.forEach((value, key) => {
        if (key !== "clientId" && key !== "redirectUri" && key !== 'username' && key !== 'tempToken') {
          additionalSearchParam = additionalSearchParam + `&${key}=${value}`;
        }
      });
  let loginPageUrl = `/login?clientId=${clientId}&redirectUri=${redirectUri}&isRedirectedFromMfa=${true}`;
  if (additionalSearchParam !== "") {
    loginPageUrl =
    loginPageUrl + additionalSearchParam;
  }

  const backHandler = () => {
    navigate(loginPageUrl, {replace: true});
  };

  const validateTempToken = async () => {
    try {
      var userNameTempToken: UserNameTempTokenDto = {
        email: userName,
        temptoken: tempToken,
      };
      var isTempTokenValid = await accountService.validateTempToken(
        userNameTempToken
      );
      if (!isTempTokenValid) {
        navigate(`/not-found`, { replace: true });
      }
    } catch (err) {
        navigate(`/unauthorize`, { replace: true });
    } finally {
      setRedirecting(true);
    }
  };

  const authenticateUserWithMFA = async () => {
    try {
      const userMfaModes = await getUserAllMfaMode();
      const userLoginDto: UserLoginRequest = {
        tempToken: tempToken,
        userName: userName,
        mfaMode: "",
        modeIdentifier: ""
      };
      if (userMfaModes && userMfaModes.length <= 0) {
        getUserBaseToken(userLoginDto);
      } else {
        //show user mfa page and follow the user authtication process
        setShowForm(true);
        setRedirecting(false);
        setUserMfaModes(userMfaModes ?? [])
        setSelectedMfaMode(userMfaModes?.at(0) ?? "")
        //api call that sends user otp at email
        sendEmailAuthToken()
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const errCode = err.response?.data?.code;
        if (errCode !== "UNAUTHORIZED") {
          const errMessage = err.response?.data?.message;
          setError(errMessage);
        }
      }
    } finally {
      setRedirecting(true);
    }
  };

  const getUserAllMfaMode = async () => {
    try {
      const response = await accountService.getUserMFAModes(userName, clientId);
      return response;
    } catch (err) {
      if (err instanceof AxiosError) {
        const errCode = err.response?.data?.code;
        if (errCode !== "UNAUTHORIZED") {
          const errMessage = err.response?.data?.message;
          setError(errMessage);
        }
      }
    } finally {
      setRedirecting(true);
    }
  };

  const getUserBaseToken = async (userLoginDto: UserLoginRequest) => {
    try {
        setRedirecting(true)
      const response: string = await accountService.login(
        userLoginDto,
        clientId
      );
      if (response) {
        navigate(loginPageUrl, { replace: true });
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const errCode = err.response?.data?.code;
        if (errCode !== "UNAUTHORIZED") {
          const errMessage = translationsLoading ? "Invalid/Expired Code" : fetchLabelKeyTranslation("InvaliOtpMsg", "Invalid/Expired Code")
          setError(errMessage);
        }
        else 
        {
            const errMessage = translationsLoading ? "One or more validation errors occurred" : fetchLabelKeyTranslation("ValidationErrMsg", "One or more validation errors occurred")
            setError(errMessage)
        }
      }
    } finally {
      setRedirecting(true);
    }
  };

  const userTokenValidationHandler = async (values: { [name: string]: any }) => {
    try {
    const userLoginDto: UserLoginRequest = {
        tempToken: tempToken,
        userName: userName,
        mfaMode: selectedMfaMode,
        modeIdentifier: values.code
    };
    getUserBaseToken(userLoginDto)
    } catch (err) {
        if (err instanceof AxiosError) {
          const errCode = err.response?.data?.code;
          if (errCode !== "UNAUTHORIZED") {
            const errMessage = err.response?.data?.message;
            setError(errMessage);
          }
        }
      } finally {
        setRedirecting(true);
      }
  };

  const sendEmailAuthToken = async () => {
    var userNameTempToken: UserNameTempTokenDto = {
        email: userName,
        temptoken: tempToken,
      }
    var codeSent = await accountService.sendMfaEmail(userNameTempToken, clientId)
    if(!codeSent)
    {
        setError("Cannot send the verification code on registered email")
    }
  }

  const resentEmailClickHandler = async () =>{
    setDisableResendEmail(true)
     setTimeout(() => {
        setDisableResendEmail(false)
     }, 30000)
     sendEmailAuthToken()
  }
  //#endregion Handlers

  return (
    <div className="loginFull float-left w-100 h-100">
      <div className="loginBg h-100 p-l-15 p-r-15">
        <div className="row m-b-20 h-100">
          <div className="col-md-12 h-100">
            {!redirecting && (
              <LoadingOverlay
                customStyle={{ position: "fixed", marginTop: "55px" }}
                themeColor={"light"}
                size={"medium"}
              />
            )}
            {showForm && <div className="formGroup h-100">
              <div
                className="formInrG cardEffect"
                style={{ position: "relative" }}
              >
                {brandingCtx?.branding?.logos.find(
                  (l) => l.name === "HeaderPrimaryLogo"
                )?.logoImageUrl && (
                  <div className="hdrTrk-logo text-center p-t-5 p-b-15">
                    {/* <span className="fs-22 font-weight">LOGO_PLACEHOLDER</span> */}
                    <img
                      src={
                        brandingCtx?.branding?.logos.find(
                          (l) => l.name === "HeaderPrimaryLogo"
                        )?.logoImageUrl
                      }
                      alt={translationsLoading ? "Logo" : fetchLabelKeyTranslation("TextLogo", "Logo")}
                    />
                  </div>
                )}
                <Form
                  initialValues={{
                    code: "",
                  }}
                  onSubmit={userTokenValidationHandler}
                  render={(formRenderProps) => (
                    <FormElement style={{ maxWidth: "100%" }}>
                      <fieldset className={"k-form-fieldset"}>
                        <legend
                          className={"k-form-legend fs-18 font-weight-semi"}
                          style={{ textTransform: "none" }}
                        >
                          {translationsLoading
                            ? "Please enter 6 digit OTP sent on your registered email"
                            : fetchLabelKeyTranslation(
                                "MfaFormTitle",
                                "Please enter 6 digit OTP sent on your registered email"
                              )}
                        </legend>
                        {error && <Error>{error}</Error>}
                        <div className="m-b-15">
                          <Field
                            placeholder={
                              translationsLoading
                                ? "Verfication Code"
                                : fetchLabelKeyTranslation(
                                    "CodeFieldPlaceholder",
                                    "Verfication Code"
                                  )
                            }
                            name="code"
                            value={formRenderProps.valueGetter("code")}
                            component={CustomInput}
                            onChange={() => {
                              setError("");
                              const enteredCode = formRenderProps.valueGetter('code')
                              if(enteredCode.length === 6)
                              {
                                 const num = Number(enteredCode)
                                 if(num > 0 && num < 1000000)
                                 {
                                    setDisableSubmitButton(false)
                                 }
                              }
                              else{
                                setDisableSubmitButton(true)
                              }
                            }}
                          />
                        </div>
                      </fieldset>
                      <div className="k-form-buttons d-flex justify-content-center m-t-15">
                        <Button
                          type={"submit"}
                          style={{ width: "100%", textTransform: "uppercase" }}
                          className={`btn bg-primary text-white fs-16 p-t-7 p-b-7 ${
                            disableSubmitButton && "disabledBtn"
                          }`}
                          disabled={disableSubmitButton}
                        >
                          <i className="bi bi-lock fs-14 p-r-5"></i>
                          {validatingCode ? (
                            <Loader
                              size="small"
                              type="infinite-spinner"
                              themeColor="light"
                            />
                          ) : translationsLoading ? (
                            "Validate OTP"
                          ) : (
                            fetchLabelKeyTranslation(
                              "SubmitButtonText",
                              "Validate OTP"
                            )
                          )}
                        </Button>
                      </div>
                      <div className="row m-t-10">
                        <div className="col-md-12">
                          <div className="formLabel p-0 d-flex justify-content-space-between">
                            <Button
                              onClick={resentEmailClickHandler}
                              className="forgotPasswordLbl btn-link"
                              disabled={disableResendEmail}
                              style={{boxShadow: "none", background: "none", border: "none"}}
                            >
                              {translationsLoading
                                ? "Resend Code ?"
                                : fetchLabelKeyTranslation(
                                    "ResendCodeText",
                                    "Resend Code ?"
                                  )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </FormElement>
                  )}
                />
                <p className="text-center text-muted m-t-6 m-b-0 fs-16">
                  {translationsLoading
                    ? "Go"
                    : fetchLabelKeyTranslation("GoText", "Go")}{" "}
                  <span
                    onClick={backHandler}
                    className="forgotPasswordLbl cursor-pointer btn-link"
                  >
                    {translationsLoading
                      ? "Back"
                      : fetchLabelKeyTranslation("BackText", "Back")}
                  </span>
                </p>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mfa;
